import service from '@/until/api'

// 中心云账号管理页---分页查询
export function _FindAllPage(data) {
  return service({
    url: '/user-nacos/user/findAllPage',
    method: 'GET',
    params: data
  })
}

//中心云账号管理---添加账号

export function _AddUserCenter(data) {
  return service({
    // url: '/user-nacos/user/addUser',
    url: '/user-nacos/user/addNewUser',
    method: 'POST',
    data
  })
}

//中心云账号管理---重置中心云用户的密码
export function _UpdatePassword(data) {
  return service({
    url: '/user-nacos/user/updatePassword',
    method: 'GET',
    params: data
  })
}
//中心云账号管理---编辑账号
export function _UpdateUserCenter(data) {
  console.log('data', data)
  return service({
    url: '/user-nacos/user/updateUserCenter',
    method: 'POST',
    data
  })
}

//中心云账号管理---添加账号---校验账号唯一
export function _CheckCenterUsername(data) {
  return service({
    url: '/user-nacos/user/checkCenterUsername',
    method: 'GET',
    params: data
  })
}

//中心云账号管理---添加账号---校验手机号唯一
export function _CheckUserCenterPhone(data) {
  return service({
    url: '/user-nacos/user/checkUserCenterPhone',
    method: 'GET',
    params: data
  })
}

//修改企业账号时---校验企业账号或园区账号是否重复
export function _CheckUpdateCenterUsername(data) {
  return service({
    url: '/user-nacos/user/checkUpdateCenterUsername',
    method: 'GET',
    params: data
  })
}
//修改企业账号时---校验手机号
export function _CheckUpdateUserCenterPhone(data) {
  return service({
    url: '/user-nacos/user/checkUpdateUserCenterPhone',
    method: 'GET',
    params: data
  })
}

//删除账号
export function _DeleteUseCenter(data) {
  return service({
    // url: '/user-nacos/user/deleteUseCenter',get
    url: '/user-nacos/user/deleteUser',
    method: 'post',
    data
  })
}
