<template>
  <div>
    <div>
      <button class="centerBtn" @click="(isAddAccount = true), (isAdd = true)">
        添加账号
      </button>
    </div>
    <div class="mt20">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchObj.username"
            placeholder="请输入账户名"
            class="centerInput"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="searchObj.phone"
            placeholder="请输入手机号"
            class="centerInput"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-select
            @change="getTableData"
            :props="{ value: 'id', label: 'roleName' }"
            v-model="searchObj.roleId"
            placeholder="请选择用户角色"
            class="centerSelInput"
            clearable
          >
            <el-option
              v-for="item in userRoleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <button class="centerCzbtn" type="button" @click="getTableData(searchPage)">
            搜索
          </button>
        </el-form-item>
      </el-form>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchObj.page"
      :size="searchObj.size"
      :isShowOverflow="false"
    />

    <!-- 添加车辆弹框 -->
    <el-drawer
      :title="isAdd ? '添加账号' : '编辑信息'"
      :visible.sync="isAddAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
      @close="closeDraw"
      :wrapperClosable="isAdd"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form" :validate-on-rule-change="false">
          <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
            <el-input
              v-model="form.username"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
            <el-input
              v-model="form.phone"
              class="centerCzInput"
              autocomplete="off"
              placeholder="请输入 (接受初始密码短信)"
              :disabled="!isAdd"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="角色" :label-width="formLabelWidth" prop="roleCenterIds">
            <el-select
              multiple
              v-model="form.roleCenterIds"
              placeholder="请选择"
              class="centerCzSelInput"
              :props="{ value: 'id', label: 'roleName' }"
            >
              <el-option
                v-for="item in userRoleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="中文名" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button
          class="centerCzbtn mr20"
          @click="confirmCancellation"
          :loading="isLoading"
          >保存</el-button
        >
        <button class="centerBtn resetbtn" @click="isAddAccount = false">取消</button>
      </div>
    </el-drawer>

    <el-drawer
      title="添加账号"
      :visible.sync="accountEdit"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <p>
          账号: <span>{{ form.zh }}</span>
        </p>
        <p>
          手机号: <span>{{ form.sjh }}</span>
        </p>
        <p>
          角色: <span>{{ form.js }}</span>
        </p>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn">保存</button>
        <button class="centerBtn resetbtn" @click="accountEdit = false">取消</button>
      </div>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="delConfirm">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="resetPassword">确 定</button>
        <button class="centerBtn resetbtn" @click="resetPwd = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-drawer
      title="账号详情"
      :visible.sync="isDetail"
      direction="rtl"
      custom-class="demo-drawer"
      class="accountDetail"
    >
      <div><span>账号：</span>{{ rowData.username }}</div>
      <div><span>手机号：</span>{{ rowData.phone }}</div>
      <div><span>角色：</span>{{ showRole(rowData) }}</div>
      <div><span>中文名：</span>{{ rowData.name }}</div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _FindAllPage,
  _AddUserCenter,
  _UpdatePassword,
  _UpdateUserCenter,
  _CheckUserCenterPhone,
  _CheckCenterUsername,
  _CheckUpdateCenterUsername,
  _CheckUpdateUserCenterPhone,
  _DeleteUseCenter,
} from "@/api/systemManagement/accountManage";
import { _FindAllRole } from "@/api/selCar";
export default {
  components: {
    Table,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      console.log(value);
      if (value) {
        console.log(reg.test(value));
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      } else {
        return callback(new Error("手机号不能为空"));
      }
      // if (!value) {
      //   return callback(new Error('手机号不能为空'));
      // } else {
      //   if (isNaN(value)) {
      //     return callback(new Error('请输入数字'));
      //   } else {
      //     if (value.toString().length != 11) {
      //       return callback(new Error('请输入11位数字'));
      //     } else {
      //       callback() //必须加
      //     }
      //   }
      // }
    };

    //用户名校验
    var validateName = (rule, value, callback) => {
      if (value === "" || value === undefined || value === null) {
        callback(new Error("账号名不能为空"));
      } else {
        if (value.length >= 15) {
          callback(new Error("账号名长度不能超过15个字母"));
          return false;
        }

        const reg = /^[a-zA-Z]*$/g;

        if (!reg.test(value)) {
          callback(new Error("账号名只能输入字母"));
          return false;
        } else {
          if (value !== "") {
            callback();
          }
        }
      }
    };

    return {
      total: 0,
      loading: false,
      isLoading: false,
      isAddAccount: false,
      accountEdit: false,
      delDia: false,
      isDetail: false,
      resetPwd: false,
      formLabelWidth: "100px",
      isAdd: false,
      searchObj: {
        username: null,
        role: null,
        page: 1,
        size: 10,
        companyId: this.$store.state.companyId,
        roleId: "",
      },
      searchPage: {
        limit: 10,
        page: 1,
      },
      userRoleList: [],
      rowData: {},
      form: {
        username: "",
        roleCenterIds: "",
        name: "",
        phone: "",
        companyId: this.$store.state.companyId,
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        roleCenterIds: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入中文名",
            trigger: "blur",
          },
        ],
      },

      tableData: [],
      columns: [
        {
          label: "账号名",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: "#1683D9",
                    cursor: "pointer",
                  },
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.isDetail = true;
                    },
                  },
                },
                params.row.username
              ),
            ]);
          },
        },
        {
          label: "手机号",
          prop: "phone",
        },
        {
          label: "激活",
          render: (h, params) => {
            return h("div", {}, [
              h("span", {style: `color: ${params.row.activate? '#2FCA3B': '#A2B2A4'}`}, '●'),
              h("span", {}, params.row.activate ? '已激活': '未激活')
            ])
          }
        },
        {
          label: "中文名",
          prop: "name",
        },
        {
          label: "角色",
          render: (h, params) => {
            let roleStr = "";
            params.row.roleCenterList.forEach((e) => {
              e.roleName ? (roleStr += e.roleName + ",") : "";
            });
            return h("div", {}, this.showRole(params.row));
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
        {
          label: "操作",
          width: 230,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.isAddAccount = true;
                      this.rowData = params.row;
                      // let { username, phone, name } = params.row;
                      let roleidList = [];
                      params.row.roleCenterList.forEach((e) => {
                        roleidList.push(e.id);
                      });
                      this.form = {
                        companyId: this.$store.state.companyId,
                        username: params.row.username,
                        phone: params.row.phone,
                        name: params.row.name,
                        roleCenterIds: roleidList,
                      };
                    },
                  },
                },
                "编辑信息"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      if(params.row.activate) {
                        this.rowData = params.row;
                        this.resetPwd = true;
                      }
                    },
                  },
                  style: `cursor: ${params.row.activate ? 'pointer': 'not-allowed'};
                          color: ${params.row.activate ? '#1683d9': '#A2B2A4'}`
                },
                "重置密码"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.rowData = params.row;

                      this.delDia = true;
                    },
                  },
                },
                "删除账号"
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    console.log(this.$store.state.companyId);
    this.getTableData();
    this.queryRoleAll();
  },
  methods: {
    // 重置方法
    reset() {
      this.searchObj.username = null;
      this.searchObj.role = null;
      this.searchObj.page = 1;
      this.searchObj.size = 10;

      this.getTableData();
    },
    //删除账号
    async delConfirm() {
      let { success, data, message } = await _DeleteUseCenter({
        userId: this.rowData.id,
      });
      if (success) {
        this.$message.success("删除成功");
        this.delDia = false;
        this.reset();
      } else {
        this.$message.error(message);
      }
    },
    confirmCancellation() {
      this.isAdd ? this.addAccount() : this.updateAccount();
    },
    // 显示角色
    showRole(row) {
      if (Object.keys(row).length == 0) {
        return "-";
      } else {
        let roleStr = "";
        row.roleCenterList.forEach((e) => {
          e.roleName ? (roleStr += e.roleName + ",") : "";
        });
        return roleStr.substring(0, roleStr.length - 1);
      }
    },
    closeDraw() {
      this.isAddAccount = false;
      this.form = {
        username: "",
        roleCenterIds: "",
        name: "",
        phone: "",
        companyId: this.$store.state.companyId,
      };
    },
    //修改信息
    updateAccount() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          _CheckUpdateCenterUsername({
            userId: this.rowData.id,
            username: this.form.username,
          }).then((res) => {
            if (res.success) {
              _CheckUpdateUserCenterPhone({
                userId: this.rowData.id,
                phone: this.form.phone,
              }).then((res) => {
                if (res.success) {
                  let newObj = JSON.parse(JSON.stringify(this.form));
                  newObj.id = this.rowData.id;
                  this.isLoading = true;
                  _UpdateUserCenter(newObj).then((res) => {
                    if (res.success) {
                      this.$message.success("修改成功");
                      this.isAddAccount = false;
                      this.getTableData();
                      this.isLoading = false;
                    } else {
                      this.$message.success(message);
                      this.isLoading = false;
                    }
                  });
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //重置密码
    async resetPassword() {
      let { success, data, message } = await _UpdatePassword({
        userId: this.rowData.id,
        platformType: 1
      });
      if (success) {
        this.$message.success("重置成功");
        this.getTableData();
        this.resetPwd = false;
      } else {
        this.$message.success(message);
      }
    },

    //添加账号
    addAccount() {
      // this.checkAccount()
      // this.checkPhone()
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          _CheckCenterUsername({
            username: this.form.username,
          }).then((res) => {
            if (res.success) {
              _CheckUserCenterPhone({
                phone: this.form.phone,
              }).then((res) => {
                if (res.success) {
                  this.isLoading = true;

                  _AddUserCenter(this.form).then((res) => {
                    if (!res) {
                      this.isLoading = false;
                    }
                    if (res.success) {
                      this.$message.success("添加成功");
                      this.isAddAccount = false;
                      this.getTableData();
                      this.isLoading = false;
                    } else {
                      this.$message.error(res.message);
                      this.isLoading = false;
                    }
                  });
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });

      // let {
      //   success,
      //   data,
      //   message
      // } = await _AddUserCenter(this.form)
      // if (success) {
      //   this.$message.success('添加成功')
      //   this.isAddAccount = false
      //   this.getTableData()
      // } else {
      //   this.$message.error(message)
      // }
    },
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      this.loading = true;
      let { message, success, data } = await _FindAllPage(this.searchObj);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },

    //查询所有角色
    async queryRoleAll() {
      console.log(localStorage.getItem("userInfo"));
      let { success, data, message } = await _FindAllRole({
        companyId: this.$store.state.companyId,
      });
      if (success) {
        this.userRoleList = data;
      }
    },

    handleClose() {},
  },
};
</script>

<style scoped lang="less">
.accountDetail {
  color: #333;
  font-size: 14px;

  div {
    margin-top: 36px;
  }

  span {
    color: #999;
    font-size: 14px;
    width: 70px;
    display: inline-block;
    text-align: right;
  }
}
</style>
